import { dicts,eventTableColumns,buildNodeForm } from "./config";
import i18n from "../../../i18n"

const lan = i18n.messages[i18n.locale];

// 元素弹框内容
// form

export const buildForm = (node, that,ruleDirective) => {
  const n = node.type + '.' +node.name; 
  //configDirective
  const cn = node.configurationDescriptor.nodeDefinition;
  const cd = cn.configDirective;
  const names = [n,...(ruleDirective[cd] || [])]
  const cnd = cn.defaultConfiguration;

  return {
    column: [
      // { label: "节点类名", prop: "clazz", value: node.clazz, disabled: true },
      // { label: "节点名称", prop: "name", value: node.name, disabled: true },
      // { label: "节点标签", prop: "description" },

      {
        prop: "configuration",
        tag: "div",
        cls: [
          ...buildNodeForm(names,cnd,that)
          // ...Object.keys(cnd).map(k => ({prop:k,label:k,value:typeof cnd[k] == 'string' ? cnd[k] : ''})),
          // ...formElemSort(Object.keys(cnd), n).map((k) =>
          //   buildFormElem(n, k, cnd[k], cnd, that)
          // ),
        ],
      },
      { label: lan['d']['describe'], prop: "description", type: "textarea" },
      { cls: lan['ruleDesignPage']['debugMode'], prop: "debugMode", tag: "el-checkbox" },
    ],
  };
};

export const buildHelp = ({type,name, configurationDescriptor }) => {
  return {
    tag: "div",
    cls: [
      {
        tag: "div",
        cls: [
          { tag: "h3", cls: type + ' ' + name },
          { tag: "p", cls: configurationDescriptor.nodeDefinition.description },
        ],
      },
      { tag: "el-divider" },
      {
        tag: "div",
        cls: [
          {
            tag: "p",
            domProps: {
              innerHTML: configurationDescriptor.nodeDefinition.description,
            },
          },
        ],
      },
    ],
  };
};


// 事件
export const buildEvent = (getEventList,id) => ({
  tag: "els-form",
    elForm:{labelWidth:'100px',size:'small'},
    class:'rule-design--node-events',
    column: [
      {
        tag: "div",
        class: "rules-design--node-event-operate",
        cls: [
          {
            tag: "el-select",
            width: "150px",
            prop: "eventName",
            label: lan['ruleDesignPage']['eventType'],
            value: "DEBUG_RULE_NODE",
            cls: [...dicts.EVENT_TYPES],
            hooks: {
              updated() {
                this.context.source.elsTableDiv &&
                  this.context.source.elsTableDiv.resetTable(this.$("value"));
              },
            },
          },
          {
            tag: "el-date-picker",
            prop: "eventTimes",
            label: lan['ruleDesignPage']['timeFrame'],
            type: "datetimerange",
            pickerOptions: {
              shortcuts: [
                {
                  text: lan['ruleDesignPage']['lastDay'],
                  onClick(picker) {
                    const end = new Date();
                    const start = new Date(end.getTime() - 3600 * 1000 * 24);
                    picker.$emit("pick", [start, end]);
                  },
                },
                {
                  text: lan['ruleDesignPage']['today'],
                  onClick(picker) {
                    const d = new Date();
                    const y = d.getFullYear();
                    const m = d.getMonth();
                    const t = d.getDate();
                    //
                    const start = new Date(y, m, t);
                    const end = new Date(start.getTime() + 3600 * 1000 * 24);
                    picker.$emit("pick", [start, end]);
                  },
                },
              ],
            },
            hooks: {
              created() {
                this.context.source.eventTimes = this;
                this.refreshTime = () => {
                  // console.log('+++++++++++++++++++')
                  const end = new Date();
                  const start = new Date(end.getTime() - 60 * 1000);
                  this.handleInput([start, end]);
                };
                //
              },
              mounted() {
                this.refreshTime();
              },
              updated() {
                this.context.source.elsTableDiv &&
                  this.context.source.elsTableDiv.apiOnload();
              },
              // updated(){
              //   this.refreshTime();
              //   // this.context.source.elsTableDiv && this.context.source.elsTableDiv.apiOnload();
              // }
            },
          },
          {
            tag: "div",
            cls: [
              {
                tag: "el-button",
                size: "mini",
                circle: true,
                icon: "el-icon-refresh",
                hooks: {
                  context(ctx) {
                    ctx.on = {
                      click: () => {
                        this.context.source.eventTimes.refreshTime() ||
                          this.context.source.elsTableDiv.apiOnload();
                      },
                    };
                  },
                },
              },
            ],
          },
        ],
      },

      { tag: "el-divider" },
      {
        tag: "div",
        style: "height:calc(100% - 32px - 16px - 49px)",
        hooks: {
          created() {
            this.context.source.elsTableDiv = this;
            this.onload = async () => {
              const n = this.context.source.formData.eventName;
              const ts = (
                this.context.source.formData.eventTimes || []
              ).map((d) => d.getTime());
              const params = { startTime: ts[0], endTime: ts[1] };
              const list = await getEventList(id, n, params);
              return { list, total: list.length };
            };
            this.apiOnload = () => {
              // console.log('-------------api onload---------',this.context.source.elsTable)
              this.context.source.elsTable &&
                this.context.source.elsTable.$refs.elsTable.apiOnload();
            };
            this.resetTable = (s) => {
              const column = eventTableColumns[s] || [];
              this.$("cls", [
                {
                  tag: "els-table",
                  ref: "elsTable",
                  key: new Date().getTime(),
                  column,
                  showMenu: false,
                  onload: this.onload,
                  hooks: {
                    created() {
                      this.context.source.elsTable = this;
                    },
                  },
                },
              ]);
            };
          },
          mounted() {
            const v = this.context.source.formData.eventName;
            this.resetTable(v);
          },
        },
      },
    ],
})
export const cellEvent = {
  label: "事件",
  name: "event",
  elem: {

  },
};
