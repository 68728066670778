<template>
  <div class="rule-design">
    <!-- 面包屑 -->
    <els-bread class="_bread"></els-bread>
    <!-- header -->
    <div class="rd--header">
      <div class="h--left">
        <i class="el-icon-link"></i>
        <b>{{ title }}</b>
      </div>
      <div class="h--right">
        <el-button
          type="primary"
          size="small"
          :disabled="drawerVisible"
          :loading="saveLoading"
          @click="handleSaveGraphData"
          >{{ lang.saveData }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          :disabled="drawerVisible"
          :loading="saveLoading"
          @click="handleClearDebugModel"
          >{{ lang.clearDebugSave }}</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="clickClose"
          :disabled="drawerVisible"
          :loading="saveLoading"
          >{{ lang.close }}
        </el-button>
      </div>
    </div>
    <!-- main -->
    <div class="rd--main">
      <els-antv-x6
        @this="(e) => (elsGraph = e)"
        :nodeList="ruleList"
        :options="options"
        :graphOptions="graphOptions"
        :eventArgs="eventArgs"
      ></els-antv-x6>
      <!-- drawer -->
      <els-drawer
        :visible.sync="drawerVisible"
        showMark
        class="rd--node-drawer"
        :showClose="false"
        :title="
          currentCellTabs && currentCellTabs.title
            ? currentCellTabs.title + currentCell.label
            : ''
        "
        :before-close="validateCellForm"
      >
        <div v-if="drawerVisible" class="drawer-tabs">
          <el-tabs v-if="currentCellTabs" v-model="currentTabName">
            <!-- <el-tab-pane name="default"></el-tab-pane> -->
            <el-tab-pane
              v-if="currentCellTabs.form"
              :label="lang.nodeAttr"
              name="form"
              class="node-form--content"
            >
              <els-form
                ref="nodeForm"
                @change-debugMode="handleChangeDebugMode"
                :elForm="{ labelWidth: '120px', labelPosition: 'top' }"
                v-bind="currentCellTabs.form"
                v-model="currentFormData"
              ></els-form>
            </el-tab-pane>
            <el-tab-pane
              v-if="currentCellTabs.table"
              :label="lang.nodeEvents"
              name="event"
            >
              <els-form
                v-model="currentEventFormData"
                v-bind="currentCellTabs.table"
              ></els-form>
            </el-tab-pane>
            <el-tab-pane
              v-if="currentCellTabs.other"
              :label="lang.helpInfo"
              name="ohter"
              class="node-info--content"
            >
              <els-elem :elem="currentCellTabs.other.elem"></els-elem>
            </el-tab-pane>
          </el-tabs>
        </div>
      </els-drawer>
    </div>

    <!-- dialog -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="edge-edit--dialog"
      :title="lang.edgeAttrEdit"
      :before-close="validateCellForm"
    >
      <els-form
        v-if="dialogVisible"
        ref="edgeForm"
        v-model="currentFormData"
        :column="currentEdgeFormColumn"
        @change="handleEdgeLabelChange"
      ></els-form>
    </el-dialog>
    <!-- funtest -->
    <el-dialog
      :visible.sync="funTestVisible"
      append-to-body
      fullscreen
      class="el-dialog--fun-test"
      :title="lang.testScriptBasic"
    >
      <div class="dialog--fun-test" v-if="funTestVisible">
        <fun-test
          :formElem="funTestElem"
          :context="context"
          v-bind="funTestConfig"
          @close="closeFunTest"
        ></fun-test>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMetadata,
  saveMetadata,
  getEventsData,
} from "@/api/manager/rules-manager.js";
//
import elsBread from "@/components/els-breadcrumb/main.vue";
import elsAntvX6 from "@/components/els-antv-x6/main";
import funTest from "./funTest.vue";
import mixinsI18n from "@/mixins/i18n.js";
//
import { saveLocal, getLocal } from "@/utils/util.js";
import { elemFunTestConfig, elsFormConfig } from "./config";
import {
  inputNode,
  graphOptions,
  DEFAUT_EDGE_EDIT,
  DEFAUT_EDGE_STATIC,
  DEFAULT_INPUT_ID,
} from "./cells";
import { buildForm, buildHelp, buildEvent } from "./cellTabs";
import events from "./events";
import { parseMetadata, parseGraphData } from "./utils";

//
import { mapState, mapGetters } from "vuex";
//
//
export default {
  name: "rule-design",
  components: { elsAntvX6, funTest, elsBread },
  mixins: [mixinsI18n],
  props: {},
  data: () => ({
    PAGE_NAME: "ruleDesignPage",
    rowId: "",
    title: "",
    elsGraph: undefined,
    graph: undefined,
    eventArgs: { "ctrl+v": { nodeProps: { data: { events: false } } } },
    drawerVisible: false,
    dialogVisible: false,
    saveLoading: false,
    //
    clearDebugDisabled: true,
    //
    ruleFormData: {},
    //
    nodeRegisterFlag: false,
    //
    currentMetadata: undefined,
    //
    currentTabName: "form",
    currentCell: undefined,
    currentFormData: {},
    currentEventFormData: {},
    //
    funTestVisible: false,
    funTestElem: undefined,
    funTestValue: "",
  }),
  computed: {
    ...mapGetters({
      breadMenus:"breadMenus",
      userInfo:"userInfo",
      ruleList: "ruleDesign/ruleList",
      ruleNodes: "ruleDesign/ruleNodes",
      ruleForm: "ruleDesign/ruleForm",
      ruleDirective: "ruleDesign/ruleDirective",
    }),
    context() {
      return this;
    },
    options() {
      return {
        edgeShape: (cell) => {
          if (/^edit-/.test(cell.shape)) {
            return DEFAUT_EDGE_EDIT;
          }
          return DEFAUT_EDGE_STATIC;
        },
      };
    },
    graphOptions() {
      return graphOptions;
    },
    currentCellTabs() {
      const cell = this.currentCell;
      if (!cell || cell.isEdge()) return undefined;
      const shape = cell.shape;
      if (!/^edit-/.test(shape)) return undefined;
      // 获取表单
      // console.log("................");
      //
      const node = this.ruleForm[shape];
      //
      const title = `【${node.type}】【${node.name}】 `;
      //
      // console.log('==============',node)
      const formElem = buildForm(node, this,this.ruleDirective);
      //
      let table = undefined;
      // 获取事件 要排除 复制粘贴的节点
      if (cell.data && cell.data.events) {
        table = { ...buildEvent(this.getEventsData, cell.id) };
      }
      // 获取其他信息
      const help = buildHelp(node);
      // console.log(help);

      // 组合
      return { title, form: { ...formElem }, other: { elem: help }, table };
    },
    currentEdgeFormColumn() {
      const cell = this.currentCell;
      if (!cell || !cell.isEdge()) return [];
      const sourceNode = cell.getSourceNode();
      console.log('----currentEdgeFormColumn---',sourceNode)
      // 取节点数据
      const node = this.ruleForm[sourceNode.shape];
      const relationTypes = node.relationTypes || [];
      const cls = relationTypes.map((v) => ({ value: v }));
      const s = sourceNode.shape.split('.').reverse()[0]
      if (
        ["TbJsSwitchNode","TbMsgTypeSpelSwitchNode"].includes(s)
        // sourceNode.shape.includes(
        //   "org.thingsboard.rule.engine.filter.TbJsSwitchNode"
        // )
      ) {
        return [
          {
            tag: "el-select",
            required: true,
            placeholder: this.lang.pleaseSelect,
            trigger: "change",
            prop: "label",
            label: this.lang.pathOpt,
            filterable: true,
            allowCreate: true,
            multiple: true,
            cls,
          },
        ];
      }
      // 生成 边表单数据
      return [
        {
          tag: "el-select",
          required: true,
          placeholder: this.lang.pleaseSelect,
          trigger: "change",
          prop: "label",
          label: this.lang.pathOpt,
          multiple: true,
          cls,
        },
      ];
    },
    //
    funTestConfig() {
      const n = this.funTestAttrName;
      if (n) {
        let res = elemFunTestConfig;
        n.split(".").forEach((k) => {
          res = res[k];
        });
        return { ...res, script: this.funTestValue };
      }
      return {};
    },
  },
  methods: {
    clickClose() {
      const r = "/" + this.$i18n.locale + "/hanyun-iot/rules";
      this.$router.push(r);
    },
    async getMetadata(id) {
      try {
        const res = await getMetadata(id);
        this.currentMetadata = res;
        return res;
      } catch (e) {
        console.log(e);
      }
      return undefined;
    },
    //
    async saveMetadata(data = {}) {
      try {
        const res = await saveMetadata(data);
        if (res) {
          this.$message.success(this.lang.saveSuccess);
          //
          this.initGraphData(res);
        }
      } catch (e) {
        console.log(e);
      }
    },
    //
    async getEventsData(id, name, params) {
      try {
        const tenantId = this.userInfo?.tenantId?.id;
        const res = await getEventsData(id, name, { ...params, tenantId });
        if (res.data) {
          return res.data.map((d) => ({
            id: d.id.id,
            createdTime: d.createdTime,
            ...d.body,
          }));
        }
        return [];
      } catch (e) {
        console.log(e);
      }
    },
    //
    handleEdgeLabelChange({ label }) {
      const value = label.length > 0 ? label.join(",") : "";
      this.currentCell.setLabels(value);
    },
    // 当元素添加到画布时触发方法
    handleCellAdded({ cell }) {
      if (!cell) return;
      const shape = cell.shape;
      if (!shape.includes("edit")) return;
      // 查找弹框内容，如果没有，不弹框 有 则打开弹框并渲染弹框内容
      this.setCurrentCell(cell);
      // 判断是否需要打开弹框
      cell?.data?.copyCellId == null && this.canOpenDrawerByCell(cell);
    },
    handleCellEdit({ cell }) {
      if (!cell) return;
      // console.log(cell);
      this.setCurrentCell(cell);
      // 判断是否需要打开弹框
      this.canOpenDrawerByCell(cell);
    },
    //
    handleChangeDebugMode(v) {
      this.clearDebugDisabled = this.clearDebugDisabled && !v;
    },
    //
    async handleClearDebugModel() {
      this.saveLoading = true;
      await this.saveGraphData(true);
      this.clearDebugDisabled = true;
      this.saveLoading = false;
    },
    // 保存图数据
    async handleSaveGraphData() {
      this.saveLoading = true;
      await this.saveGraphData();
      this.saveLoading = false;
    },
    async saveGraphData(mode) {
      if (!this.graph) {
        this.$message.error(this.lang.noGraph);
        return;
      }
      const cells = this.graph.getCells();
      //
      // console.log(cells)
      const inputNode = this.graph.getCellById(DEFAULT_INPUT_ID);
      const firstEdge = cells.filter(
        (cell) => cell.isEdge() && cell.getSourceCell() == inputNode
      )[0];
      const firstNode = firstEdge ? firstEdge.getTargetCell().id : undefined;
      const nodes = cells
        .filter((cell) => cell.isNode() && cell != inputNode)
        .map((cell) => ({
          id: cell.id,
          ...cell.position(),
          label: cell.label,
          shape: cell.shape,
          clazz: cell.shape.replace(/^\w+-/, ""),
        }));
      const edges = cells
        .filter((cell) => cell.isEdge() && cell != firstEdge)
        .map((cell) => ({
          id: cell.id,
          source: cell.getSourceCell().id,
          target: cell.getTargetCell().id,
        }));
      const metadata = parseGraphData(
        { nodes, edges, firstNode, ruleChainId: this.rowId },
        this.ruleFormData,
        mode ? false : undefined
      );
      //
      await this.saveMetadata(metadata);
    },
    //
    setCurrentCell(cell) {
      const id = cell.id;
      const cid =
        cell.isNode() && cell.data && cell.data.copyCellId
          ? cell.data.copyCellId
          : undefined;
      if (this.ruleFormData[id]) {
        this.currentFormData = { ...this.ruleFormData[id] };
      } else if (cell.isEdge()) {
        const labels = cell.getLabels();
        const label = labels.length > 0 ? labels[0].attrs.label.text : "";
        if (label) {
          this.currentFormData = { label: label.split(",") };
        }
      } else if (cid && this.ruleFormData[cid]) {
        this.currentFormData = { ...this.ruleFormData[cid] };
      }
      this.currentCell = cell;
    },
    //
    canOpenDrawerByCell(cell) {
      if (cell.isEdge()) {
        this.dialogVisible = true;
      }
      // 判断元素当前状态
      if (cell.isNode()) {
        this.currentTabName = "form";
        this.currentEventFormData = {};
        this.drawerVisible = true;
      }
    },
    //
    async validateCellForm(done) {
      if (!this.currentCell) {
        done();
        return;
      }
      try {
        if (this.currentCell.isEdge()) {
          await this.validateForm("edgeForm");
        }
        if (this.currentCell.isNode()) {
          await this.validateForm("nodeForm");
        }
        done();
        // 数据保存 清空当前表单数据
        this.saveFormData();
        //
        //
      } catch (e) {
        console.log(e);
      }
    },
    // 边表单验证 验证通过，关闭对话框，否则不关闭对话框
    async validateForm(ref) {
      if (this.$refs[ref]) {
        return this.$refs[ref].validate();
      } else {
        return true;
      }
    },
    //
    saveFormData() {
      // console.log(this.currentCell);
      const id = this.currentCell.id;
      this.ruleFormData[id] = { ...this.currentFormData };
      this.$nextTick(() => {
        this.currentFormData = {};
      });
    },
    //
    getCellsFormData(ids = []) {
      const res = {};
      ids.forEach((id) => {
        res[id] = this.ruleFormData[id];
      });
      return res;
    },
    setCellsFormData(formData = {}) {
      Object.keys(formData).forEach((id) => {
        this.ruleFormData[id] = formData[id];
      });
    },
    getCellEvent() {},
    getCellInfo() {},
    //
    showFunTest(n, v, e) {
      // console.log('........fun test..........',n,v,e)
      this.funTestAttrName = n;
      this.funTestVisible = true;
      this.funTestValue = v;
      this.funTestElem = e;
    },
    closeFunTest() {
      this.funTestVisible = false;
      this.funTestAttrName = "";
      this.funTestElem = undefined;
    },
    //
    initRowData() {
      //const rowId = this.$route.query.rowId;
      const { id, name, root } = this.$route.params.id
        ? this.$route.params
        : this.$route.query;
      this.rowId = id;
      this.title = `${root ? "【ROOT】" : ""} ${name}`;
      // console.log(this.$route.params);
    },
    //
    initGraphNode() {
      if (this.elsGraph && !this.nodeRegisterFlag) {
        this.elsGraph.apiRegistCell("input-static", inputNode);
        //
        let ct = 0;
        this.ruleNodes.forEach(({ name, cell }) => {
          this.elsGraph.apiRegistCell(name, cell);
          ct++;
        });
        if (ct > 0 && ct === this.ruleNodes.length) {
          this.nodeRegisterFlag = true;
        }
      }
    },
    // 添加初始节点
    // initInputNode() {
    //   if (this.graph) {
    //     this.graph
    //       .addNode({
    //         id:DEFAULT_INPUT_ID,
    //         shape: "input-static",
    //         label: "开始",
    //         icon: "el-icon-arrow-right",
    //         data:{static:true}
    //       })
    //       .setData({ static: true });
    //   }
    // },
    // 初始化事件
    initGraphEvents() {
      if (this.graph) {
        Object.keys(events).forEach((k) => {
          this.graph.on(k, events[k]);
        });
        //
        this.graph.on("node:added", (e) => {
          this.handleCellAdded(e);
        });
        this.graph.on("edge:connected", (e) => {
          this.handleCellAdded({ cell: e.edge, ...e });
        });
        this.graph.on("cell:edit", (e) => {
          // console.log('...........',e)
          this.handleCellEdit(e);
        });
        this.graph.on("cells:copy", (cells) => {
          const data = this.getCellsFormData(cells.map((cell) => cell.id));
          saveLocal("copyCellsFormData", data);
          // console.log('+++++ > ',cells,data)
        });
        this.graph.on("cells:paste", (cells) => {
          const d = getLocal("copyCellsFormData", "object");
          const t = {};
          cells.forEach((cell) => {
            const id = cell.id;
            if (cell.isEdge()) {
              const labels = cell.getLabels();
              const label = labels.length > 0 ? labels[0].attrs.label.text : "";
              t[id] = { label: label.split(",") };
            } else {
              const cid = cell.data.copyCellId;
              t[id] = d[cid];
            }
          });
          //
          this.setCellsFormData(t);
          // console.log('+++++ > ',cells,d,t,this.ruleFormData)
        });
      }
    },
    // 初始化 metadata
    async initMetadata() {
      if (!this.rowId) return;
      try {
        // console.log("---------------");
        const metadata = await this.getMetadata(this.rowId);
        // 生成图形数据和表单
        this.initGraphData(metadata);
      } catch (e) {
        console.error(e);
      }
    },
    initGraphData(metadata) {
      const { nodes, edges, form } = parseMetadata(metadata);
      // 生成图形数据
      this.graph.fromJSON({ nodes, edges });
      //
      this.ruleFormData = { ...form };
    },
    // 初始化 metadata 图数据 不包括  初始节点
    // initGraphData() {
    //   if (!this.graph) return;
    //   // 清楚图上除了 input 节点外所有元素
    //   this.graph.clearCells();
    //   this.initInputNode();
    //   // 生成图数据
    //   // 加载图数据
    // },
  },
  created() {
    // this.getNodes();
    this.initGraphNode();
    //
    this.initRowData();
  },
  mounted() {
    if (this.elsGraph && this.elsGraph.graph) {
      this.graph = this.elsGraph.graph;
      //
      // console.log('................',this.graph)
      this.initGraphNode();
      // //
      // this.initInputNode();
      // //
      this.initGraphEvents();

      //
      this.initMetadata();
    }
    //
    // console.log(this.ruleNodes,this.ruleList)
    //

    // this.initGraphData();
  },
};
</script>

<style lang="scss" scoped>
.rule-design {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  ._bread {
    height: 20px;
  }
  .rd--header {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 2px solid #ddd;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 8px;
    padding: 0 8px;

    .h--left {
      display: flex;
      align-items: center;

      i {
        margin-right: 1em;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .rd--main {
    position: relative;
    box-sizing: border-box;
    padding: 8px;
    background: #fff;
    height: calc(100% - 64px - 40px);
  }

  &::v-deep .rd--node-drawer {
    background: #00000022;

    .els-drawer--content {
      width: 50%;
    }

    // .drawer-tabs{
    //   padding:0 10px;
    // }
    //
    .drawer-tabs {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      .el-tabs {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        .el-tabs__content {
          height: calc(100% - 55px);
          box-sizing: border-box;
          padding: 8px;
        }
      }

      .el-tabs__header {
        .el-tabs__nav {
          &:first-child {
            margin-left: 2em;
          }
        }
      }
    }

    .el-tab-pane {
      box-sizing: border-box;
      overflow: auto;
      height: 100%;
      padding: 0 8px;

      .g--fieldset {
        width: 100%;
        min-width: 0;
        box-sizing: border-box;
      }

      .rule-design--node-events {
        height: 100%;

        .el-form {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.edge-edit--dialog {
  .el-dialog {
    width: 500px;

    .el-select {
      width: 390px;
    }
  }
}
</style>

<style lang="scss">
.tip {
  color: #aaa;
  font-size: 12px;
  line-height: 1em;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.els-form {
  .inline {
    display: inline-block;
    margin-right: 16px;
  }

  .el-select {
    width: 100%;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
}

.els-form--item {
  margin: 20px 0;

  .editor--tip {
    // margin-left:40px;
    font-size: 12px;
    color: #aaa;
    padding: 5px 0;

    .form-item--required {
      margin-right: 1em;
      color: red;
    }
  }

  .els-monaco-editor {
    height: 120px;
    border: 1px solid #ddd;
  }
}

.rules-design--node-event-operate {
  display: flex;
  margin-top: 1em;
  justify-content: space-between;

  //
  .el-form-item {
    > * {
      display: inline;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      display: flex;
    }

    margin-bottom: 0 !important;

    label {
      margin-right: 1em;
    }
  }
}

.el-dialog--fun-test {
  .el-dialog {
    display: flex;
    flex-direction: column;

    .el-dialog__body {
      flex: 1;
    }
  }
}

.dialog--fun-test {
  width: 100%;
  height: 100%;
  // overflow: auto;
}

.message-content {
  min-height: 40px;
  max-height: 500px;
  overflow: auto;
}

.el-message-box {
  width: auto;
}
</style>
