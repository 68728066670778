export default {
  'node:labelEdit':({cell,oldValue,newValue}) => {
    const info = cell.getAttrs().info.text;
    const bool = newValue == info;
    //console.log('..........',cell,cell.getAttrs().info,info,bool,oldValue,newValue)
    //
    if(!info && !bool && newValue != oldValue){
      cell.setProp({info:oldValue})
    }
    else if(info){
      if(!newValue){
        cell.setProp({label:info,info:''})
      }else if(bool){
        cell.setProp({info:''})
      }
    }
  },
  // 'node:dblclick':({cell}) => {
  //   cell.notify('edit',cell)
  // }
}